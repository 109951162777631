.container {
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  color: #8900C4;
}

.container > :nth-child(1) {
  position: absolute;
  width: 8rem;
  left: 30%;
  top: -3rem;
}

.products {
  display: grid;
  width: 90%;
  grid-template-columns: 25% auto;
}

.menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-weight: 500;
  font-size: 1.3rem;
}

.menu > li:hover {
  color: var(--green);
  cursor: pointer;
}

.active {
  color: white;
  font-weight: bold;
}

.categoryHeader {
  cursor: default;
  font-weight: bold;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  height: relative;
  width: relative;
  overflow-x: hidden; 
  gap: 2rem;
}

.product {
  border: 2px solid #8900C4;
  background-color: black;
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product > img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.product:hover {
  border-color: var(--green);
  color: var(--green); /* Added hover effect */
}

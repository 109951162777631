.cFooterWrapper{
    width: 100%;
    margin-top: 5rem;
}
.cFooterWrapper>hr{
     width: 100%;
     height: 1px;
    border: none;
    background: white;
    margin-top: 1rem;
}
.cFooter{
    display: flex;
    width: 100%;
    justify-content: space around;
    background: #2BA000;
    color: #000000;
}
.logo{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}
.logo>span{
    font-weight:600;
    font-size:1rem;
}
.block{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: 5%;
}
.detail{
    display: flex;
    flex-direction:column;
    width: inherit;
    font-size: 7rem;
    gap: 1rem;
    font-style: italic;
}
.detail>span:nth-of-type(1){
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
}
.pngLine:hover{
    cursor: pointer;

}
.pngLine{
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 0.8rem;
}
.icon{
    width: 25px;
    
}
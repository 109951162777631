.container {
  
  padding: 1.5rem;
  display: flex;
  background: #2BA000;
  color: #000000;
}
.logo {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 1rem;
  color: #000000;
}

.logo > img {
  width: 2.5rem;
  height: 2.5rem;
}
.logo > span {
  font-weight: 600;
}

.right {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.menu {
  display: flex;
  gap: 2rem;
  font-weight: 500;
  list-style: none;
}
menu > li:hover {
  color: #fe956f;
  cursor: pointer;
}

.search {
  width: 7rem;
  outline: none;
  height: 30%;
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
}

.right .cart {
  cursor: pointer;
}

@media screen and (max-width: 640px){
    .right{
        position: absolute;
        right: 2rem;
        z-index: 9999;
        background: white;
        color: (--black);
        border-radius: 5px;
        flex-direction: column;
        padding: 1rem;
    }
    .menu{
        flex-direction: column;
        margin-left: -2rem;
        display: none;
    }
    .bars{
        display: block;
    }
    .search{
        display: none;
    }
}
